.header-bottom{
	// padding: 10px 0px;
	// background-color: #000;
	// border: 2px dotted red;
	// border-radius: 20px;
	color: #fff;
	background-color: #000;
	padding: 15px 0px;
	.frm-search{
		width: 100%;
		display: flex;
		position: relative;
		.s-mobile{
			width: 100%;
			background-color: #fff;
			border: none;
			border-radius: 5px;
			line-height: 40px;
			padding: 0px 15px;
		}
		.search-button{
			background-color: transparent;
			border-radius: 0px;
			border: none;
			position: absolute;
			right: 0px;
			font-size: rem-calc(18);
			line-height: 40px;
			// color: #fff;
			padding: 0px 15px;
		}
	}
}
