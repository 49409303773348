.menu-fixed-side{
	position: fixed;
  	-ms-transform: translateX(280px);
    -webkit-transform: translateX(280px);
    transform: translateX(280px);
  	background-color: #fff;
  	min-height: 100%;
  	height: 100vh;
  	z-index: 99999;
  	width: 280px;
  	top: 0;
  	right: 0px;
  	-webkit-transition: all 0.2s ease;
  	-moz-transition: all 0.2s ease;
  	transition: all 0.2s ease-in-out;
  	opacity: 1;
  	overflow: hidden;
  	overflow-y: scroll;
	ul{
		padding: 0;
		margin: 0;
		list-style: none;
	}
}
.menu-fixed-side.show{
	-webkit-box-shadow: -1px 2px 19px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 19px -1px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 19px -1px rgba(0,0,0,0.75);
}
.menu-fixed-side>ul{
	li{
		a{
			display: block;
			position:relative;
			padding:10px;
			padding-left:15px;
			color:#333;
			text-transform: capitalize;
			text-decoration: none;
			border-top: 1px solid rgba(255, 255, 255, 0.1);
    		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
			span{
				position: absolute;
			    right: 0px;
			    display: inline-block;
			    text-align: left;
			    width: 60px;
			    top: 0;
			    bottom: 0;
			    line-height: 38px;
			    small{
			    	padding: 0px 10px;
				    width: 40px;
				    border: 1px solid #ddd;
				    border-radius: 10px;
				    font-size: 14px;
			    }
			    .fa-angle-right {
				    position: absolute;
				    right: 10px;
				    font-size: 22px;
				    line-height: 36px;
				}
			}
		}

		ul{
			position: fixed;
			background-color: #fff;
			min-height: 100%;
			z-index: 9999;
			width: 280px;
			top: 0;
			right: 0px;
			-webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			transition: all 0.2s ease-in-out;
			opacity: 1;
			-ms-transform: translateX(280px);
		    -webkit-transform: translateX(280px);
		    transform: translateX(280px);

		}
	}
}
.show-sb {
	-ms-transform: translateX(0px) !important;
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
 }

.list-fixed-side{
	position: fixed;
	display: flex;
	z-index: 9997;
	right:14px;
	bottom:120px;
	flex-direction: column;
	-webkit-flex-direction:column;
	a{
		display: inline-block;
	    width: 50px;
	    height: 50px;
	    text-align: center;
	    line-height: 50px;
	    background-color: $color-2st;
	    color: #fff;
	    border-radius: 100%;
	    margin-bottom: 15px;
	    position: relative;
		i{
			font-size: 18px;
		}
		.label{
			position: absolute;
			left: 0px;
			top: 10px;
			width: 90px;
			height: 30px;
			background-color: $color-2st;
			border-radius: 3px;
			content: "";
			line-height: 30px;
			margin-left: -95px;
			&:before{
				top: 50%;
			    margin-top: -5px;
			    right: -5px;
			    position: absolute;
			    content: "";
			    width: 0px;
			    height: 0px;
			    border-top: 5px solid transparent;
			    border-left: 5px solid $color-2st;
			    border-bottom: 5px solid transparent;
			}
		}
	}
	.back-to-top{
		background-color: $color-1st;
	}
}

.back-menu,
.back-to-page {
    position: relative;
    display: block;
    padding: 12px 10px;
    background: $color-2st;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    &:hover{
    	cursor: pointer;
    }
    i {
	    position: absolute;
	    left: 15px;
	    font-size: 22px;
	    line-height: 18px;
	}
}

.btn-blur{
	webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -ms-filter: blur(2px);
    -o-filter: blur(2px);
    filter: blur(2px);
}

.show {
    -ms-transform: translateX(0px) !important;
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
}
.header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.53);
    z-index: 9999;
}