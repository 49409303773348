.pro-detail{
	padding-top: 30px;
}
.wrap-pro-detail{
	display: flex;
	flex-wrap: wrap;
	.pro-detail-img{
		width: 40%;
	}
	.pro-detail_content{
		width: 60%;
		width: calc(60% - 30px);
		margin-left: 30px;
		border: 1px solid $color-3st;
		border-radius: 10px;
		padding: 15px;
		.pro-props__name{
			font-size: 18px;
			font-weight: 400;
			color: $color-2st;
			padding-bottom:15px;
		}
		.pro-props{
			.pro-props__price{
				margin: 5px 0px;
			    font-size: 20px;
			    font-weight: 700;
			    color: $color-3st;
			    display: inline-block;
			}
			.pro-props__oldprice{
				margin-left: 10px;
			    text-decoration: line-through;
			    color: gray;
			    font-size: 16px;
			    display: inline-block;
			}
		}
		.pro-props__attr{
			padding: 15px 0px;
			padding-left: 15px;
			li{
				padding-bottom: 5px;
				span{
					// color: #fff;
				}
			}
		}
		.masanpham{
			padding:0px;
			margin:0px;
			list-style: none;
			li{
				font-weight: 400;
				font-size: 13px;
				padding-right: 15px;
				&:last-child{
					padding-right: 0px;
				}
				&:first-child{
					color: $color-4st;
				}
			}
		}
		.pro-desc{
			display: inline-table;
			width: 100%;
			border-radius: 10px;
			padding: 10px 15px;
			font-size: rem-calc(14);
			border: 1px dotted rgba(255,255,255,.5);
		}
		.pro-property{
			margin-top: 15px;
			.property-item{
				.qty {
				    display: inline-block;
				    position: relative;
				    margin-right: 40px;
				    #quantity-detail {
					    line-height: 39px;
					    margin: 0;
					    display: inline-block;
					    width: 100px;
					    padding-right: 0;
					    height: 40px;
					    width: 40px;
					    margin-left: 40px;
					    border: none;
					    border: 1px solid #ddd;
					    &:focus{
					    	border: 1px solid #ddd;
					    }
					}

				    .qtyminus {
				    	position: absolute;
				    	text-align: center;
					    right: 40px;
					    top: 0px;
					    font-size: 12px;
					    width: 40px;
					    height: 40px;
					    cursor: pointer;
					    border: none;
					    background-color: #f4f4f4;
					    display: block;
					    line-height: 40px;
					}
					.qtyplus {
						position: absolute;
						text-align: center;
					    right: -40px;
					    top: 0px;
					    width: 40px;
					    height: 40px;
					    cursor: pointer;
					    border: none;
					    background-color: #f4f4f4;
					    display: block;
					    line-height: 40px;
					}
				}
			}
			.buy-now{
				.btn-muangay{
					background-color: $color-2st;
					text-transform: uppercase;
					color: #fff;
					border: none;
					display: inline-block;
					padding: 8px 30px;
					font-weight: $font-bold;
					margin-left: 10px;
					border-radius: 3px;
					cursor: pointer;
					&:hover{
						background-color: $color-3st;
						color: #fff;
					}
				}
			}
		}
	}

	@media(max-width: 991px){
		.pro-detail-img{
			width: 100%;
		}
		.pro-detail_content{
			width: 100%;
			margin-left: 0px;
			border: none;
			padding: 0px;
			padding-top: 15px;
		}
		.pro-detail-other{
			width: 100%;
			border: none;
			padding: 15px 10px;
			border: 1px solid #ddd;
			border-radius: 10px;
			margin: 15px 0px;
		}
	}
}



.pro-gioithieu,
.pro-tskt,
.pro-commnet{
	padding-top: 30px;
}
.title-pro-meta{
	display: inline-block;
	font-size: 22px;
	font-weight: 700;
	color: #000;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 15px;

	&:before{
		position: absolute;
		width: 50px;
		height: 2px;
		background-color: #000;
		bottom: 0px;
		left: 0px;
		content: "";
	}
}

@media(max-width: 767px){
	@media(max-width: 576px){
		.wrap-pro-detail{
			.pro-detail_content{
				.pro-property{
					.buy-now{
						.btn-muangay{
							margin-top: 10px;
							width: 50%;
							width: calc(50% - 2.5px);
							margin-right: 5px;
							display: block;
							margin-left: 0px;
							padding: 5px 0px;
							text-align: center;
							float: left;
							&:last-child{
								margin-right: 0px;
							}
						}
					}
				}
			}
		} 
	}
}