.br-crumb{
	padding: 15px 0px;
	margin-bottom: 0px;
	background-color: $color-1st;
	.breadcrumb{
		margin-bottom: 0;
		background-color: transparent;
		padding: 0px 0px;
		.breadcrumb-item + .breadcrumb-item:before{
			padding: 0 10px;
		    color: $color-3st;
		    content: "\f105";
		    font-family: fontAwesome;
		}
		li{
			color: $color-3st;
			a{
			    color: $color-3st;
			    transition: color 0.2s ease-in-out 0s;
			    text-decoration: none;
			    &:hover{
			    	text-decoration: underline;
				}
			}
			@include headings{
				display: inline-block;
				padding: 0px;
				margin: 0px;
				font-size: 14px;
				font-weight: $font-regular;
			}
			h1{
				font-size: 14px;
				font-weight: $font-regular;
			}
		}
	}
}