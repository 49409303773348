/* Custom Mixins -------------------- */
@mixin headings {
    h1, h2, h3,
    h4, h5, h6, .tit {
        @content;
    }
}

@mixin font-general($font-family: arial, $font-size: 14, $font-weight: 400, $line-height: 1.5em) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}
