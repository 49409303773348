.bottom-footer{
	// padding: 15px 0px;
	padding-bottom: 30px;
	font-size: rem-calc(16);
	.line{
		margin-top: 30px;
		padding-top: 15px;
		border-top: 2px solid rgba(255,255,255,1);
	}
    a{
		color: $color-2st;
		text-decoration: none;
    }
}