body{
	max-width: 100%;
	overflow-x: hidden;
	font-size: rem-calc(14);
	background-color: #fff;
	color: #333;
	font-weight: $font-regular;
	// font-family: 'Roboto', sans-serif;
}
img{
	max-width: 100%;
}
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
// mobile
@media (max-width: 767px){
	header {
	    border-bottom-color: white; //rgb(255, 255, 255)
	}
}
//table
@media (min-width: 768px){
	header {
	    border-bottom-color: red; //rgb(255, 0, 0)
	}
}
//computer
@media (min-width: 992px){
	header {
	    border-bottom-color: yellow; //rgb(255, 255, 0)
	}
}
