// .sidebox{
// 	.post{
// 		display: flex;
// 		.post-img{
// 			width: 40%;
// 			padding-right: 15px;
// 			img{
// 				display: block;
// 				width: 100%;
// 				height: auto;
// 				transform: initial;
//     			opacity: initial;
// 				&:hover{

// 				}
// 			}
// 		}
// 		.post-cap{
// 			width: 60%;
// 			@include headings{
// 				font-size: 14px;
// 				line-height: 1.4;
// 				margin-bottom: 3px;
// 				a{
// 					font-size: 14px;
// 					line-height: 1;	
// 					font-weight: 500;
// 				}
// 			}
// 			p{
// 				font-size: 12px;
// 			    color: #222;
// 			}
// 		}
// 	}
// }

@media (max-width: 767px){
	// .sidebox{
	// 	.post{
	// 		margin-bottom: rem-calc(15);
	// 	}
	// }
	// @media (min-width: 576px){

	// }
}

@media (min-width: 768px){

}

@media (min-width: 992px){
	// .sidebox{
	// 	.post{
	// 		margin-bottom: 15px;
	// 	}
	// }
}

@media (min-width: 1200px){
	
}

/*Danh mục menu trái phải*/
.top-pro-new{
	.cate-menu{
		list-style: none;
		padding: 0px;
		margin: 0px;
		li{
			position: relative;
			list-style: none;
			i{
			    display: inline-block;
			    text-align: center;
			    height: 25px;
			    width: 25px;
			    line-height: 23px;
			    color: $color-3st;
			    padding-top: 0px;
			    position: absolute;
			    top: 8px;
			    right: 0px;
			    -ms-transform: rotate(90deg);
			    -webkit-transform: rotate(90deg);
			    transform: rotate(90deg);
			    border: 1px solid $color-3st;
			    border-radius: 100%;
			}
			a{
			    padding: 10px 0px;
			    color: $color-2st;
			    font-size: rem-calc(16);
			    border-bottom: 1px solid rgba(0,0,0,.2);
			    text-transform: capitalize;
			    display: inline-table;
			    width: 100%;
			    &:hover{
			    	color: $color-3st;
			    }
			}
			.cate-menu-sub1{
				padding: 0px;
				margin: 15px 0px;
				list-style: none;
				background-color: #fff;
				padding-left: 30px;
				li{
					a{
						color: #888;
						font-size: rem-calc(14);
						padding: 5px 0px;
						border-bottom: none;
						&:hover{
							color: $color-3st;
						}
					}
					&:last-child a{
						border-bottom: 0px;
					}
					&.activeleft{
						a{
							color: $color-3st;
						}
					}
				}
			}
			&.activeleft{
				a{
					color: $color-3st;
				}
			}
			&:last-child{
				a{
					border-bottom: none;
				}
			}
		}
	}
}